import * as React from 'react';

import SEO from '../components/global/seo';
import OtherLayout from '../components/other/other-layout';

const NotFoundPage = () => (
  <OtherLayout>
    <SEO title="404: Not found" />
    <h1>404: NOT FOUND</h1>
    <p>Et voilà, vous êtes perdu.</p>
    <p>C’est quand même un comble de vous perdre sur notre site, alors que nous voulons vous
       éviter de vous égarer dans vos projets. Essayez d’y mettre un peu plus de bonne volonté.</p>
  </OtherLayout>
);

export default NotFoundPage;

